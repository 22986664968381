<template>
  <div class="history-wrapper">
    <el-form
      label-width="90px"
      class="params-form"
      :inline="true"
    >
      <el-form-item label="警情类型:">
        <el-select v-model="alarmType"></el-select>
      </el-form-item>
      <el-form-item label="起止时间：">
        <div class="block">
          <el-date-picker
            v-model="value"
            type="datetimerange"
            :picker-options="pickerOptions"
            format="yyyy-MM-DD HH:mm:ss"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            align="right"
          >
          </el-date-picker>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          @click="getHistoryData"
          style="margin-left:50px"
        >查询</el-button>
        <el-button @click="printTable">打印</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="historyData"
      stripe
      style="width: 100%"
      class="history-table"
      height="100%"
    >
      <template slot="empty">点击查询刷新历史记录</template>

      <el-table-column
        prop="id"
        label="id"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="name"
        label="设备"
        width="180"
      >
      </el-table-column>
      <el-table-column
        prop="alarmType"
        label="警情类型"
        width="180"
      >
      </el-table-column>
      <el-table-column
        prop="value"
        label="报警值"
        width="180"
      >
      </el-table-column>
      <el-table-column
        prop="time"
        label="报警时间"
      >
      </el-table-column>
      <el-table-column
        prop="serial"
        v-if="1 ==0"
      >
      </el-table-column>
      <el-table-column
        prop="handletime"
        label="处理时间"
      >
      </el-table-column>
      <el-table-column
        prop="account"
        label="处理账户"
      >
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        
        background
        layout="sizes,prev, pager, next"
        :page-sizes="[50, 100, 200, 500]"
        :page-size="pagesize"
        :total="total"
        @size-change="sizeChange"
        @current-change="pageChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import printJS from 'print-js'
var apiServer = "http://39.105.155.249:3000"
export default {
  data() {
    return {
      value: [],
      alarmType:'',
      historyData:[],
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      pagesize:100,
      total:0,
      startPage:0
    };
  },
  methods:{
    getHistoryNum(){
      let url = apiServer + "/historyNum";
      return new Promise((resolve, reject) => {
        axios
          .get(url,{
            params:{
              startTime:this.value[0],
              endTime:this.value[1]
            }
          })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    getHistoryData(){
      this.getHistoryNum().then(result=>{
        if(result.count > 0){
          this.total = result.count;
          let url = apiServer + '/history';
          axios.get(url,{params:{
            startTime:this.value[0],
            endTime:this.value[1],
            startpage:this.startPage,
            limit:this.pagesize
          }}).then(result=>{
            this.historyData = result.data.data;
          }).catch(e=>{
            console.log(e);
          })
        }
      }).catch(e=>{
        console.log(e);
      })
    },

    pageChange(n){
      this.startPage = this.pagesize * (n-1);
      this.getHistoryData();
    },

    sizeChange(n){
      this.startPage = 0;
      this.pagesize = n;
      this.getHistoryData();
    },
    printTable(){
      printJS({
        printable:this.historyData,
        properties: [
          {
            field: 'id',
            displayName: 'ID',
            columnSize: 1
          },
          {
            field: 'name',
            displayName: '设备名称',
            columnSize: 1
          },
          {
            field: 'alarmType',
            displayName: '警情类型',
            columnSize: 1
          },
          {
            field: 'value',
            displayName: '警情值',
            columnSize: 1
          },
          {
            field: 'time',
            displayName: '警情时间',
            columnSize: 1
          },
          {
            field: 'handletime',
            displayName: '处理时间',
            columnSize: 1
          }
        ],
        type:'json',
        gridStyle: 'border: 2px solid #3971A5;',
        gridHeaderStyle: 'color: red;  border: 2px solid #3971A5;'
      });
      console.log('打印');
    }
  }
};
</script>

<style lang="less" scoped>
.params-form /deep/ .el-form-item__content {
  text-align: left;
}

.history-table /deep/ .el-table__body-wrapper {
  height: calc(100vh - 231px);
  max-height:calc(100vh - 231px);
}

.params-form {
  text-align: left;
}

.history-wrapper {
  margin: 0 auto;
  margin-top: 20px;
}
</style>